
















































































import {
  defineComponent,
  toRef,
  useContext,
  useRouter,
  computed,
  ref,
  onMounted, onUnmounted
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

export default defineComponent({
  name: 'SearchResults',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    searchResults: {
      type: Array as PropType<Product[] | null | Object>,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: '',
    },
    searchLoading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    SkeletonLoader
  },
  setup(props, { emit }) {
    const searchResults = toRef(props, 'searchResults')
    const { app } = useContext();
    const router = useRouter();
    const { app: { i18n } } = useContext();
    const placeHolder = i18n.t('Looking for equipment or attachments?');
    const highlightSearchTerm = (name, searchTerm) => {
      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regexPattern = new RegExp(`(${escapedSearchTerm})`, 'gi');
      return name.toLowerCase().replace(regexPattern, `<span class='highlight'>${escapedSearchTerm}</span>`);
    };
    const checkingForSku = (sku, search) => !!sku.includes(search);
    const openSelected = async (url) => {
      emit('hide-search-result', true);
      await router.push({ path: url })
    }
    const term = ref('');
    const searchResultCount = computed(() => searchResults.value?.length ?? 0)
    const activeTab = ref('buy')
    const handleKeydownEnter = (searchTerm: string) => {
      searchTerm = searchTerm.replace(/^\s+/, '');
      emit('search-term-mobile', searchTerm)
    };
    const changeOption = (option) => {
      activeTab.value = option
      if (props.searchTerm) {
        emit('change-option', option)
      }
    }
    const clearSearch = () => {
      term.value = '';
      emit('search-term-mobile', term.value)
    }
    const searchResultRestrict = computed(() => searchResults.value?.slice(0, 15))
    const triggerSearch = (event) => {
      emit('triggerSearch', true)
    }
    onMounted(() => {
      document.body.style.overflow = 'hidden';
      if(process.browser) {
        window.addEventListener('load', function() {
          const absoluteElement = document.getElementById('search-box');
          if(!absoluteElement) return
          const contentHeight = absoluteElement.scrollHeight;
          absoluteElement.style.height = contentHeight + 'px';
        });
      }
    })
    onUnmounted(() => {
      document.body.style.overflow = 'auto';
    })
    return {
      highlightSearchTerm,
      handleKeydownEnter,
      checkingForSku,
      triggerSearch,
      openSelected,
      changeOption,
      clearSearch,
      searchResultRestrict,
      searchResultCount,
      placeHolder,
      activeTab,
      term,
      app
    };
  },
});
